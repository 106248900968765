<template>
  <portal>

    <v-row>
      <v-col cols="12">

        <v-breadcrumbs
          :items="breadcrumbsItems"
          class="pa-0 mb-8"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>

      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex flex-row">

        <span class="d-flex align-center justify-center">
          <v-icon color="secondary" size="36" class="mr-3">mdi-view-compact</v-icon>
          <h1 class="darkGrey--text">Vote Formats</h1>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="darkGrey"
                size="18"
                class="ml-3"
                v-bind="attrs"
                v-on="on"
              >
                mdi-help-circle-outline
              </v-icon>
            </template>
            <span>
              Explanation for Agenda Styles
            </span>
          </v-tooltip>
        </span>

        <v-spacer></v-spacer>

        <v-btn
          depressed
          height="40"
          color="orange lighten-2"
          class="white--text"
          @click="openModal('createUpdate', 'create')"
        >
          <v-icon size="18">mdi-plus</v-icon> Add Vote Format
        </v-btn>

      </v-col>
    </v-row>

    <v-row class="my-6">
      <v-col>
        <v-divider class="custom-divider"></v-divider>
      </v-col>
    </v-row>

    <v-row>
      <v-col>

        <v-row>
          <v-col>
            <v-card>
              <v-data-table
                :headers="headers"
                :items="voteFormats"
                :items-per-page="5"
                :options.sync="options"
                :loading="loading"
                :search="search"
                :server-items-length="totalVoteFormats"
                class="elevation-1"
                :footer-props="{
              'items-per-page-options': [10, 20, 30]
            }"
              >
                <template v-slot:item.name="{ item }">
                  <span class="py-6 d-flex flex-column">
                    <span class="font-weight-bold">
                      {{ item.name }}
                    </span>
                    <span class="text-caption">
                      {{ item.description }}
                    </span>
                  </span>
                </template>
                <template v-slot:item.status="{ item }">
                  <v-tooltip top v-if="item.active">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="positive"
                        size="24"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-check-circle-outline
                      </v-icon>
                    </template>
                    <span>
                      Active
                    </span>
                  </v-tooltip>
                  <v-tooltip top v-if="item.pending">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="grey"
                        size="24"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-timer-sand
                      </v-icon>
                    </template>
                    <span>
                      Pending
                    </span>
                  </v-tooltip>
                  <v-tooltip top v-if="item.paused">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="important"
                        size="24"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-pause
                      </v-icon>
                    </template>
                    <span>
                      Paused
                    </span>
                  </v-tooltip>
                </template>
                <template v-slot:item.actions="{ item }">
                  <div class="d-flex flex-row justify-center">

                    <v-menu transition="slide-x-transition">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                        >
                          <v-icon size="20">mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item-group>
                          <v-list-item
                            v-for="(itemMenu, index) in itemsMenuFiltered(item)"
                            :key="index"
                            @click="openModal(itemMenu.actions, itemMenu.optional, item)"
                            dense
                          >
                            <v-list-item-icon>
                              <v-icon
                                :color="itemMenu.type? itemMenu.type:'darkGrey'"
                              >
                                {{ itemMenu.icon }}
                              </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title
                                :class="itemMenu.type? itemMenu.type+'--text':'darkGrey--text'"
                              >
                                {{ itemMenu.text }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-menu>

                  </div>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>

      </v-col>
    </v-row>

    <vote-format-create-update @reload="getDataFromApi" :allVoteFormats="allVoteFormats" :voteFormat="voteFormat" v-model="modals.createUpdate" :modeType="modeType"></vote-format-create-update>
    <vote-format-settings @reload="getDataFromApi" :voteFormat="voteFormat" :voteFormatId="voteFormat.id"  v-model="modals.settings"></vote-format-settings>
    <vote-format-activate @reload="getDataFromApi" :voteFormat="voteFormat" v-model="modals.activate"></vote-format-activate>
    <vote-format-resume @reload="getDataFromApi" :voteFormat="voteFormat" v-model="modals.resume"></vote-format-resume>
    <vote-format-pause @reload="getDataFromApi" :voteFormat="voteFormat" v-model="modals.pause"></vote-format-pause>
    <vote-format-delete @reload="getDataFromApi" :voteFormat="voteFormat" v-model="modals.delete"></vote-format-delete>

  </portal>
</template>

<script>
import Vue from 'vue'
import Portal from '@/components/Portal'
import VoteFormatCreateUpdate from '@/components/VoteFormat/VoteFormatCreateUpdate'
import VoteFormatSettings from '@/components/VoteFormat/VoteFormatSettings'
import VoteFormatActivate from '@/components/VoteFormat/VoteFormatActivate'
import VoteFormatResume from '@/components/VoteFormat/VoteFormatResume'
import VoteFormatPause from '@/components/VoteFormat/VoteFormatPause'
import VoteFormatDelete from '@/components/VoteFormat/VoteFormatDelete'
import VoteFormat from '@/models/VoteFormat'

export default Vue.extend({
  name: 'VoteFormat',
  components: {
    Portal,
    VoteFormatCreateUpdate,
    VoteFormatSettings,
    VoteFormatActivate,
    VoteFormatResume,
    VoteFormatPause,
    VoteFormatDelete
  },
  data: () => ({
    options: {},
    search: '',
    loading: false,
    voteFormats: [],
    voteFormat: {},
    allVoteFormats: [],
    totalVoteFormats: 0,
    fab: false,
    modeType: '',
    modals: {
      createUpdate: false,
      settings: false,
      activate: false,
      resume: false,
      pause: false,
      delete: false
    },
    breadcrumbsItems: [
      {
        text: 'Templates',
        disabled: false,
        href: ''
      },
      {
        text: 'Vote Formats',
        disabled: true,
        href: ''
      }
    ],
    itemsMenu: [
      { icon: 'mdi-pencil', text: 'Edit', actions: 'createUpdate', optional: 'edit' },
      { icon: 'mdi-ballot', text: 'Vote Settings', actions: 'settings' },
      { icon: 'mdi-content-copy', text: 'Duplicate', actions: 'createUpdate', optional: 'duplicate' },
      { icon: 'mdi-check-circle-outline', text: 'Activate', actions: 'activate' },
      { icon: 'mdi-play', text: 'Resume', actions: 'resume' },
      { icon: 'mdi-pause', text: 'Pause', type: 'important', actions: 'pause' },
      { icon: 'mdi-delete', text: 'Delete', type: 'important', actions: 'delete' }
    ],
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Status', value: 'status', sortable: false, align: 'center' },
      { text: 'Actions', value: 'actions', sortable: false, width: '150px', align: 'center' }
    ],
    voteFormatSample: [
      { name: 'Standard', description: 'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Morbi eu mi dolor', active: true, pending: false, paused: false },
      { name: 'City Council Vote', description: 'In vitae Leo sem. Nam nec rutrum elit. Donec at volutpat quam.', active: false, pending: true, paused: false },
      { name: 'Simple Vote Format', description: 'Donec varius, ligula vitae porta sollicitudin, magna enim vehicula justo, ut elementum nibh eros suscipit nulla. ', active: false, pending: false, paused: true },
      { name: 'Yes and No Only', description: 'Sollicitudin, magna enim vehicula justo, ut elementum nibh eros suscipit nulla. ', active: true, pending: false, paused: false },
      { name: 'EEDC Vote Format', description: 'Magna enim vehicula justo, ut elementum nibh eros suscipit nulla. ', active: true, pending: false, paused: false }
    ]
  }),
  methods: {
    openModal (item, optional, voteFormat) {
      if (optional) this.modeType = optional
      this.modals[item] = true
      if (voteFormat) this.voteFormat = voteFormat
    },
    getDataFromApi () {
      this.loading = true
      const {
        sortBy,
        sortDesc,
        page,
        itemsPerPage
      } = this.options

      let orderBy = null
      if (sortBy !== undefined && sortBy[0] !== undefined) {
        const sortDir = sortDesc[0] ? '-' : ''
        orderBy = sortDir + sortBy[0]
      }

      VoteFormat
        .page(page)
        .where('client_id', this.$store.state.client.activeClient.clientID)
        .orderBy(orderBy)
        .get()
        .then(response => {
          // console.log('response', response)
          this.voteFormats = response.data
          this.totalVoteFormats = response.total
          this.voteFormats.forEach(elm => {
            elm.active = false
            elm.pending = false
            elm.paused = false
            elm.reserved = false
            elm[elm.status] = true

            if (elm.status === 'inactive') {
              elm.paused = true
            }

            elm.coversheets = 1
            elm.sections = 1
          })
          this.loading = false
        })
    },
    itemsMenuFiltered (item) {
      const itemsToRemove = []

      if (item.status === 'pending') {
        itemsToRemove.push('resume', 'pause')
      }

      if (item.status === 'active') {
        itemsToRemove.push('resume', 'activate')
      }

      if (item.status === 'inactive') {
        itemsToRemove.push('pause', 'activate')
      }

      return this.itemsMenu.filter(function (el) { return !itemsToRemove.includes(el.actions) })
    }
  },
  watch: {
    options: {
      handler () {
        this.getDataFromApi()
      },
      deep: true
    }
  },
  mounted () {
    this.options = {
      page: 1,
      itemsPerPage: 10
    }
    this.getDataFromApi()
  },
  created () {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'client/setActiveClient') {
        if (state.activeClient !== null) {
          this.getDataFromApi()
        }
      }
    })
  }
})
</script>

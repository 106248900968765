<template>

  <v-dialog
    width="900"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
        <span>Vote Settings for <span class="font-weight-black">{{ voteFormat.name }}</span></span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-text>

        <v-tabs
          vertical
          color="secondary"
          v-model="activeTab"
        >
          <v-tab class="mr-8 d-flex justify-start" name="actionLabelTab">
            Action Labels
          </v-tab>
          <v-tab class="mr-8 d-flex justify-start" name="nameCountTab">
            Name / Count
          </v-tab>
          <v-tab class="mr-8 d-flex justify-start" name="resultTextTab">
            Result Text
          </v-tab>

          <v-tab-item>
            <v-card flat>
              <v-card-text>

                <v-form ref="actionLabelsForm" lazy-validation @submit.prevent="">
                  <v-text-field
                    outlined
                    label="New action label"
                    clearable
                    v-model="actionLabelValue"
                    @focus="addActionLabelFocus=true"
                    @blur="addActionLabelFocus=false;resetForm($refs.actionLabelsForm)"
                    :rules="rules.actionLabelRules"
                    :error-messages="customErrorMessages.actionLabel"
                  >
                    <template v-slot:append>
                      <v-icon
                        v-if="!addActionLabelFocus"
                        color="lightGrey"
                      >
                        mdi-plus-circle
                      </v-icon>
                      <v-icon
                        v-else
                        color="secondary"
                        @click="addActionLabel(actionLabelValue)"
                      >
                        mdi-plus-circle
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-form>
                <v-container v-if="loading">
                  <v-row>
                    <v-col
                      cols=12>
                      <v-skeleton-loader
                        class="mx-auto"
                        type="list-item-avatar"
                      ></v-skeleton-loader>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-skeleton-loader
                        class="mx-auto"
                        type="divider"
                      ></v-skeleton-loader></v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols=12>
                      <v-skeleton-loader
                        class="mx-auto"
                        type="list-item-avatar"
                      ></v-skeleton-loader>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-skeleton-loader
                        class="mx-auto"
                        type="divider"
                      ></v-skeleton-loader></v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols=12>
                      <v-skeleton-loader
                        class="mx-auto"
                        type="list-item-avatar"
                      ></v-skeleton-loader>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-skeleton-loader
                        class="mx-auto"
                        type="divider"
                      ></v-skeleton-loader></v-col>
                  </v-row>
                </v-container>
                      <v-container v-if="!loading">
                        <draggable
                      v-model="actionLabels"
                      @start="startingDrag()"
                      @end="endingDrag()"
                      v-bind="dragOptions">
                        <v-row
                          v-for="(item,n) in actionLabels"
                          :key="n"
                          class="draggableRow"
                        >
                          <v-col>
                            <v-icon color="lightGrey" class="handle">
                              mdi-drag-horizontal-variant
                            </v-icon>
                          </v-col>
                          <v-col>{{ item.name }}</v-col>
                          <v-col>
                            <span class="d-flex justify-end">

                              <v-menu
                                offset-y
                                top
                                left
                                :close-on-content-click="false"
                                :close-on-click="true"
                                v-if="!item.active"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    color="darkGrey"
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                  >
                                    <v-icon size="18"
                                      @click="setActionLabelInputValue(item)">
                                      mdi-pencil</v-icon>
                                  </v-btn>
                                </template>
                                <v-list width="400">
                                  <v-list-item>
                                    <v-text-field
                                      v-model="actionLabelInputValue"
                                      :rules="rules.actionLabelRules">
                                    <!-- v-model="item.name" -->
                                      <template v-slot:append>
                                        <v-btn
                                          color="darkGrey"
                                          icon
                                          @click="saveActionLabelInputValue(item)"
                                        >
                                          <v-icon
                                            color="secondary"
                                          >
                                            mdi-send
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                    </v-text-field>
                                  </v-list-item>
                                </v-list>
                              </v-menu>

                              <v-speed-dial
                                direction="left"
                                v-model="item.active"
                                v-if="actionLabelsDelete"
                              >
                                <template v-slot:activator>
                                  <v-btn
                                    icon
                                    v-model="item.active"
                                  >
                                    <v-icon color="important" size="18">
                                      mdi-delete
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <v-btn
                                  fab
                                  dark
                                  x-small
                                  color="grey"
                                >
                                  NO
                                </v-btn>
                                <v-btn
                                  fab
                                  dark
                                  x-small
                                  color="important"
                                  @click="deleteActionLabel(n)"
                                >
                                  YES
                                </v-btn>
                              </v-speed-dial>

                            </span>
                          </v-col>
                        </v-row>
                        </draggable>
                        </v-container>

              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text>

                <v-row>
                  <v-col class="d-flex">
                    <v-checkbox
                      v-model="viewName"
                      label="Names"
                      hide-details
                      class="pa-0 ma-0 mr-8"
                      color="secondary"
                      :disabled="!viewCount"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="viewCount"
                      label="Count"
                      hide-details
                      class="pa-0 ma-0"
                      color="secondary"
                      :disabled="!viewName"
                    ></v-checkbox>
                  </v-col>
                </v-row>

                <v-card elevation="0" color="veryLightGrey" class="d-flex mt-6">
                  <v-card-text>

                    <span v-if="viewCount && !viewName">
                      <span
                        v-for="(item,n) in actionLabels"
                        :key="n"
                        class="darkGrey--text text-lg-body-1"
                      >
                        {{ nameCountSample[n].count }} <span class="font-weight-bold">{{ item.name }}</span><span v-if="n != Object.keys(actionLabels).length - 1">,</span>
                      </span>
                    </span>

                    <v-simple-table
                      class="transparent"
                      v-if="!viewCount && viewName || viewCount && viewName"
                    >
                      <template v-slot:default>
                        <tbody>
                        <tr
                          v-for="(item,n) in actionLabels"
                          :key="n"
                        >
                          <td width="30%">
                            <span class="font-weight-bold">{{ item.name }}</span>
                          </td>
                          <td v-if="viewCount" :width="viewName ? '15%' : '70%'">
                            <v-chip>{{ nameCountSample[n].count }}</v-chip>
                          </td>
                          <td v-if="viewName" :width="viewCount ? '55%' : '70%'">
                            <span >
                              {{ nameCountSample[n].names }}
                            </span>
                          </td>
                        </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>

              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text>

                <v-form ref="resultsTextForm" lazy-validation @submit.prevent="">
                  <v-text-field
                    outlined
                    label="New result text"
                    clearable
                    v-model="resultTextValue"
                    @focus="addResultTextFocus=true"
                    @blur="addResultTextFocus=false;resetForm($refs.resultsTextForm)"
                    :rules="rules.resultsTextRules"
                    :error-messages="customErrorMessages.resultText"
                  >
                    <template v-slot:append>
                      <v-icon
                        v-if="!addResultTextFocus"
                        color="lightGrey"
                      >
                        mdi-plus-circle
                      </v-icon>
                      <v-icon
                        v-else
                        color="secondary"
                        @click="addResultText(resultTextValue)"
                      >
                        mdi-plus-circle
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-form>
                <v-container>
                  <draggable
                  v-model="resultTexts"
                  @start="startingDrag()"
                  @end="endingDrag()"
                  v-bind="dragOptions">
                    <v-row
                      v-for="(item,n) in resultTexts"
                      :key="n"
                      class="draggableRow"
                    >
                      <v-col width="10%">
                        <v-icon color="lightGrey" class="handle">
                          mdi-drag-horizontal-variant
                        </v-icon>
                      </v-col>
                      <v-col>{{ item.name }}</v-col>
                      <v-col align="right" width="150">
                        <span class="d-flex justify-end">

                          <v-menu
                            offset-y
                            top
                            left
                            open-on-click
                            :close-on-content-click="false"
                            v-if="!item.active"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                color="darkGrey"
                                v-bind="attrs"
                                v-on="on"
                                icon
                              >
                                <v-icon size="18"
                                  @click="setResultTextsInputValue(item)">
                                  mdi-pencil</v-icon>
                              </v-btn>
                            </template>
                            <v-list width="400">
                              <v-list-item>
                                <v-text-field
                                  v-model="resultTextsInputValue"
                                  :rules="rules.resultsTextRules">>
                                  <template v-slot:append>
                                    <v-btn
                                      color="darkGrey"
                                      icon
                                      @click="saveResultTextsInputValue(item)"
                                    >
                                      <v-icon
                                        color="secondary"
                                        @click="testFunction"
                                      >
                                        mdi-send
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                </v-text-field>
                              </v-list-item>
                            </v-list>
                          </v-menu>

                          <v-speed-dial
                            direction="left"
                            v-model="item.active"
                            v-if="resultTextsDelete"
                            :value="openDeleteActionLabelMenu"
                          >
                            <template v-slot:activator>
                              <v-btn
                                icon
                                v-model="item.active"
                                @click="openDeleteActionLabelMenu = true"
                              >
                                <v-icon color="important" size="18">
                                  mdi-delete
                                </v-icon>
                              </v-btn>
                            </template>
                            <v-btn
                              fab
                              dark
                              x-small
                              color="grey"
                            >
                              NO
                            </v-btn>
                            <v-btn
                              fab
                              dark
                              x-small
                              color="important"
                              @click="deleteResultText(n)"
                            >
                              YES
                            </v-btn>
                          </v-speed-dial>

                        </span>
                      </v-col>
                    </v-row>
                  </draggable>
                </v-container>
              </v-card-text>
            </v-card>
          </v-tab-item>

        </v-tabs>

      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="closeModal()">
          <span>Close</span>
        </v-btn>
        <v-btn v-if="settingsDirty" depressed width="130" @click="saveSettings()" class="green white--text">
          <v-progress-circular v-if="saving===true" indeterminate size="16"></v-progress-circular>
          Save Settings
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'
import draggable from 'vuedraggable'
import axios from 'axios'
import VoteFormatSetting from '@/models/VoteFormatSetting'

export default Vue.extend({
  name: 'VoteFormatSettings',
  props: {
    value: {
      required: true,
      type: Boolean
    },
    voteFormatId: {
      required: false,
      type: Number
    },
    voteFormat: {}
  },
  components: {
    draggable
  },
  data: () => ({
    saving: false,
    loading: false,
    tab: 0,
    addActionLabelFocus: false,
    addResultTextFocus: false,
    actionLabelsDelete: true,
    resultTextsDelete: true,
    actionLabelValue: '',
    customErrorMessages: {
      actionLabel: '',
      resultText: ''
    },
    resultTextValue: '',
    viewName: false,
    viewCount: false,
    actionLabels: [],
    resultTexts: [],
    nameCountSample: [
      { names: 'Simon, Reis, Caron, Carter, Smith, Brown', count: 6 },
      { names: 'Edwards, Leroux', count: 2 },
      { names: 'Miller', count: 1 },
      { names: 'Davis, Johnson', count: 2 },
      { names: 'Garcia, Jones, Rodriguez', count: 3 },
      { names: 'Lopez, Thompson, Lee, Taylor', count: 4 },
      { names: '', count: 0 },
      { names: 'Gonzalez, White, Lewis', count: 3 },
      { names: 'Anderson, Silva, King', count: 3 },
      { names: 'Hill', count: 1 }
    ],
    rules: {
      actionLabelRules: [
        v => !!v || 'Label cannot be blank',
        v => (v && v.length <= 50) || 'Label must be less than 50 characters'
      ],
      resultsTextRules: [
        v => !!v || 'Results text cannot be blank',
        v => (v && v.length <= 50) || 'Results text must be less than 50 characters'
      ]
    },
    dragging: false,
    settingsDirty: false,
    activeTab: 'actionLabelTab',
    openDeleteActionLabelMenu: false,
    initialVoteFormatSettings: {},
    currentVoteFormatSettings: {},
    actionLabelInputValue: '',
    resultTextsInputValue: ''
  }),
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    dragOptions () {
      return {
        handle: '.handle',
        animation: 500,
        ghostClass: 'ghostClass',
        dragClass: 'dragClass',
        chosenClass: 'chosenClass'
      }
    }
  },
  watch: {
    show (value) {
      if (value === true) {
        this.getDataFromApi()
      } else {
        this.actionLabels = []
        this.resultTexts = []
      }
    },
    actionLabels: {
      handler: function () {
        if (this.actionLabels.length <= 2) {
          this.actionLabelsDelete = false
        } else {
          this.actionLabelsDelete = true
        }

        this.setCurrentVoteFormatSettings()
      }
    },
    resultTexts: {
      handler: function () {
        if (this.resultTexts.length <= 1) {
          this.resultTextsDelete = false
        } else {
          this.resultTextsDelete = true
        }
        this.setCurrentVoteFormatSettings()
      }
    },
    viewName: {
      handler: function () {
        this.setCurrentVoteFormatSettings()
      }
    },
    viewCount: {
      handler: function () {
        this.setCurrentVoteFormatSettings()
      }
    },
    currentVoteFormatSettings: {
      handler: function () {
        this.compareToInitialSettings()
      }
    }
  },
  methods: {
    testFunction () {
      console.log(1)
    },
    setCurrentVoteFormatSettings () {
      this.currentVoteFormatSettings = { actionLabels: this.actionLabels, viewName: this.viewName, viewCount: this.viewCount, resultTexts: this.resultTexts }
    },
    getDataFromApi () {
      this.loading = true

      axios.get(process.env.VUE_APP_BACKEND_URL + '/api/vote-format-settings/' + this.voteFormatId)
        .then((response) => {
          for (const actionLabelIndex in response.data.actionLabels) {
            this.actionLabels.push({ name: response.data.actionLabels[actionLabelIndex], active: false })
          }

          for (const resultTextIndex in response.data.resultTexts) {
            this.resultTexts.push({ name: response.data.resultTexts[resultTextIndex], active: false })
          }

          this.viewName = response.data.resultDisplay.names
          this.viewCount = response.data.resultDisplay.count

          // Deep copy the initial values to compare on change
          this.initialVoteFormatSettings = { actionLabels: JSON.parse(JSON.stringify(this.actionLabels)), viewName: this.viewName, viewCount: this.viewCount, resultTexts: JSON.parse(JSON.stringify(this.resultTexts)) }

          this.loading = false
        }).catch(function (error) {
          console.log(error)
        }).then(() => {
          // this.readyForAPI = true
        })
    },
    compareToInitialSettings () {
      if (JSON.stringify(this.currentVoteFormatSettings) === JSON.stringify(this.initialVoteFormatSettings)) {
        this.settingsDirty = false
      } else {
        this.settingsDirty = true
      }
    },
    addActionLabel (item) {
      if (this.$refs.actionLabelsForm.validate()) {
        if (this.isUniqueValue(item, this.actionLabels, 'name')) {
          this.customErrorMessages.actionLabel = ''
          this.actionLabels.push({ name: item, active: false })
          this.actionLabelValue = ''
          this.resetForm(this.$refs.actionLabelsForm)
        } else {
          this.customErrorMessages.actionLabel = 'You must enter a unique action label'
        }
      }
    },
    addResultText (item) {
      if (this.$refs.resultsTextForm.validate()) {
        if (this.isUniqueValue(item, this.resultTexts, 'name')) {
          this.customErrorMessages.resultText = ''
          this.resultTexts.push({ name: item, active: false })
          this.resultTextValue = ''
          this.resetForm(this.$refs.resultsTextForm)
        } else {
          this.customErrorMessages.resultText = 'You must enter a unique result text'
        }
      }
    },
    isUniqueValue (value, array, key) {
      for (const currentValue in array) {
        if (value.toLowerCase() === array[currentValue][key].toLowerCase()) {
          return false
        }
      }
      return true
    },
    clearCustomErrorMessages () {
      for (const errorIndex in this.customErrorMessages) {
        this.customErrorMessages[errorIndex] = ''
      }
    },
    resetForm (form) {
      // triggered on field blur
      form.resetValidation()
      form.reset()
      this.clearCustomErrorMessages()
    },
    deleteActionLabel (item) {
      this.actionLabels.splice(item, 1)
    },
    deleteResultText (item) {
      this.resultTexts.splice(item, 1)
    },
    startingDrag () {
      this.dragging = true
    },
    endingDrag () {
      this.dragging = false
    },
    saveSettings () {
      this.saving = true

      axios.put(process.env.VUE_APP_BACKEND_URL + '/api/vote-format-settings/' + this.voteFormatId, this.formattedForSaving())
        .then((response) => {
          this.saving = false
          this.show = false
          this.$emit('reload', true)
          this.closeModal()
        })
    },
    formattedForSaving () {
      const formattedForSaving = { actionLabels: [], resultTexts: [], resultDisplay: { count: this.viewCount, names: this.viewName } }
      const voteFormatSettingsArrays = ['actionLabels', 'resultTexts']

      for (const voteFormatSettingsArrayIndex in voteFormatSettingsArrays) {
        for (const index in this.currentVoteFormatSettings[voteFormatSettingsArrays[voteFormatSettingsArrayIndex]]) {
          formattedForSaving[voteFormatSettingsArrays[voteFormatSettingsArrayIndex]].push(this.currentVoteFormatSettings[voteFormatSettingsArrays[voteFormatSettingsArrayIndex]][index].name)
        }
      }

      return formattedForSaving
    },
    resetModal () {
      this.dragging = false
      // this.compareToInitialSettings()
    },
    closeModal () {
      this.settingsDirty = false
      this.resetModal()
      this.activeTab = 'actionLabelTab'
      this.show = false
    },
    setActionLabelInputValue (item) {
      this.actionLabelInputValue = item.name
    },
    saveActionLabelInputValue (item) {
      if (this.actionLabelInputValue !== '') {
        item.name = this.actionLabelInputValue
        this.compareToInitialSettings()
      }
    },
    setResultTextsInputValue (item) {
      this.resultTextsInputValue = item.name
    },
    saveResultTextsInputValue (item) {
      if (this.resultTextsInputValue !== '') {
        item.name = this.resultTextsInputValue
        this.compareToInitialSettings()
      }
    }
  }
})

/*
  For Action Labels it's needed at least TWO options (and they shouldn't have the remove button)
  For Result Text it's needed at least ONE option (and it shouldn't has the remove button)
*/

</script>

<style>
.draggableRow {
  border-bottom:solid 1px lightgrey;
  background-color: light;
}
.draggableRow:first-child .col {
   border-top:solid 1px lightgrey;
}
.ghostClass {
  height: 60px;
  border: solid 2px rgb(38, 74, 98) !important
}
.chosenClass {
  background-color: rgb(230, 244, 253);
}
.dragClass {
  border: solid 2px rgb(124, 46, 46) !important
}
</style>
